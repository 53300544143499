import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';

import { Notification, Section } from 'shared-library';

import { buildStudySelector } from '../../store/studies/selectors';
import { buildPatientsSelector } from '../../store/patients/selectors';
import { fetchPatients } from '../../store/patients/actions';
import { fetchNpNotesForStudy } from '../../store/studies/actions';
import { UserId } from '../../types';
import { Patient } from '../../store/patients/types';

import Log from './Log';

const getNameFromAuthors = (id: UserId, authors: Record<UserId, Patient>) => {
  if (id === 'Admin') {
    return id;
  }
  const author = authors[id];
  if (author) {
    return `${author.firstname} ${author.lastname}`;
  }
  return 'Unknown';
};

const LogContainer = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const { log } = useSelector(selectStudy);
  const [isLoading, setLoading] = useState(false);
  const logAuthorIDs = log.map((_) => _.authorID).filter((id) => id !== 'Admin');
  const logAuthors = useSelector(buildPatientsSelector(logAuthorIDs));
  const isAuthorCacheIncomplete = Object.keys(logAuthors).length < logAuthorIDs.length;
  const formattedLog = log
    .map((item, index) => ({ ...item, index }))
    .sort((a, b) => {
      const timeDiff = Number(b.time) - Number(a.time);
      // Sort logs by time by default, use index as second parameter if time is same
      if (timeDiff !== 0) {
        return timeDiff;
      }
      return b.index - a.index;
    })
    .map(({ authorID, content, category, time }) => ({
      authorID: getNameFromAuthors(authorID, logAuthors),
      content,
      time,
      category: category || 'General',
    }))
    .filter((item) => item.content);

  useEffect(() => {
    const fetchAuthors = async () => {
      setLoading(true);
      try {
        await fetchPatients(logAuthorIDs)(dispatch);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        addToast(
          Notification.create(
            'Error fetching authors',
            'Something unexpected went wrong when fetching author names. Give it another try and please report an issue if this persists.',
          ),
          { appearance: 'error' },
        );
      }
    };
    fetchNpNotesForStudy(selectedStudyId)(dispatch)
    if (isAuthorCacheIncomplete) {
      fetchAuthors();
    }
  }, []);

  return (
    <Section loading={isLoading} title="Activity Log">
      <Log log={formattedLog} />
    </Section>
  );
};

export default LogContainer;
