import styled from 'styled-components';

import { colors, Text } from 'shared-library';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

interface ColumnProps {
  full?: boolean;
  custom?: string;
}

interface ButtonProps {
  justifyContent?: string;
}

export const Column = styled.div<ColumnProps>`
  flex: 0 0 ${({ full, custom }) => (full ? '100%' : custom || '33.33333%')};
  ${({ full }) => !full && 'padding-right: 16px;'}
  margin-bottom: 16px;
`;

export const Label = styled(Text).attrs({
  size: 'small',
  color: colors.ash,
  weight: '600',
})`
  margin-bottom: 1px;
`;
export const InlineBlock = styled.div`
  display: inline-block;
`;

export const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  gap: 12px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;
