import React from 'react';
import Log from './LogContainer';
import AddNoteForm from './AddNoteForm';

const ActivityLog = () => (
  <>
    <AddNoteForm />
    <Log />
  </>
);

export default ActivityLog;
