/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { formatUnixDatetime } from 'packages/formatters';
import { colors, Card, Text } from 'shared-library';

import { useSelector } from 'react-redux';
import { LogItem } from '../../types';
import { selectStaff } from '../../store/staff/selectors';

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const Timestamp = styled(Text)`
  display: inline;
`;

const Author = styled(Text).attrs({
  size: 'small',
  color: colors.ash,
})`
  display: inline;
`;
const Category = styled(Text).attrs({
  size: 'small',
  color: colors.ash,
})`
  display: inline;
`;

interface LogProps {
  log: Array<LogItem>;
}

const Log = ({ log }: LogProps): React.ReactElement => {
  const [logs, setLogs] = useState([]);
  const staff = useSelector(selectStaff);

  useEffect(() => {
    if (log && staff) {
      const newLog = log.map((logItem) => {
        const auth0Ids = logItem.content.match(/auth0\|[^ ]+/g);
        const hasAuth0Ids = auth0Ids?.length > 0;
        const newLogItem = {
          ...logItem,
        };
        if (hasAuth0Ids) {
          const mappedIds = auth0Ids?.map((id) => id.split('.')[0]);
          const members = staff.data.filter((member) => mappedIds.includes(member.userId));
          if (members?.length > 0) {
            members.forEach((member) => {
              newLogItem.content = newLogItem.content.replaceAll(
                member.userId,
                `${member.firstname || ''} ${member.lastname || ''}`,
              );
            });
          }
        }
        return newLogItem;
      });

      setLogs(newLog);
    }
  }, [log, staff]);

  return (
    <List>
      {logs.map(({ authorID, content, category, time }, index) => (
        <Card as="li" key={`${time}-${content.replace(/\s/g, '-').substring(0, 10)}-${index}`}>
          <div>
            <Timestamp>{formatUnixDatetime(time)}</Timestamp>
            {' / '}
            <Author>{authorID}</Author>
            {' / '}
            <Category>{category}</Category>
          </div>
          <Text>{content}</Text>
        </Card>
      ))}
    </List>
  );
};

export default Log;
