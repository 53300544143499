import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  colors,
  Button,
  LoadingSpinner,
  Notification,
  Section,
  SelectInput,
  Text,
  TextAreaInput,
} from 'shared-library';

import { addLogItem } from '../../store/studies/actions';
import { Row, Column } from '../Layout';

const interactionTypes = ['Phone', 'Email', 'In-person', 'Internal note', 'Chat'];
const interactionTypeOptions = interactionTypes.map((value) => ({ label: value, value }));

const reqErrMsg = 'This field is required';

const validationSchema = yup.object().shape({
  interactionType: yup
    .object()
    .shape({
      label: yup.string().required(reqErrMsg),
      value: yup.string().required(reqErrMsg),
    })
    .required(reqErrMsg),
  body: yup.string().required(reqErrMsg),
});

const ErrorText = styled(Text).attrs({
  color: colors.error,
  weight: '600',
})`
  font-size: 12px;
`;

const AddNoteForm = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { studyId } = useParams();
  const { control, errors, handleSubmit, reset } = useForm({ validationSchema });
  const [isSavingNote, setSavingNote] = useState<boolean>(false);

  const handleNoteSubmit = async ({ interactionType, body }) => {
    setSavingNote(true);
    const category = interactionType.value
    const content = body;
    try {
      await addLogItem(studyId, content, category)(dispatch);
      setSavingNote(false);
      addToast(
        Notification.create('Added note', 'Successfully created a note and added it to the log.'),
        { appearance: 'success' },
      );
      reset({ interactionType: null, body: '' });
    } catch (err) {
      setSavingNote(false);
      addToast(
        Notification.create(
          'Adding note failed',
          'Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
    }
  };

  return (
    <Section title="Add Note">
      <form onSubmit={handleSubmit(handleNoteSubmit)}>
        <Row>
          <Column>
            <Controller
              as={SelectInput}
              control={control}
              disabled={isSavingNote}
              label="Interaction Type"
              name="interactionType"
              options={interactionTypeOptions}
              variant="primary"
            />
            <ErrorMessage as={ErrorText} errors={errors} name="interactionType" />
          </Column>
        </Row>
        <Row>
          <Column full>
            <Controller as={TextAreaInput} control={control} disabled={isSavingNote} name="body" />
            <ErrorMessage as={ErrorText} errors={errors} name="body" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Button disabled={isSavingNote} type="submit" variant="primary" size="medium">
              {isSavingNote ? <LoadingSpinner color={colors.white} size="small" /> : 'Add'}
            </Button>
          </Column>
        </Row>
      </form>
    </Section>
  );
};

export default AddNoteForm;
